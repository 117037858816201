import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, message } from "antd";
import { useNavigate, useLocation } from "react-router";
import { MdOutlineEmail } from "react-icons/md";
import SignUpImg from "../../assets/SignupImg.png";
import Logo from "../../assets/Logo.png";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { VerifyOTPAPI } from "../../Constants/Api/Api";
import "./NewVerifyOtp.scss";

const NewVerifyOtp = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const email = location?.state;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = (values) => {
    const headers = {
      role_id: 8,
    };
    const formData = {
      email: email,
      otp: values.otp,
      type: "email_varification",
    };

    // Call your OTP verification API here
      VerifyOTPAPI(formData, headers)
        .then((res) => {
     
          if (res.data.success) {
            message.success(res.data.message);
            navigate("/login");
          } else {
            message.error(res.data.message);
          }
        })
        .catch((e) => {
          console.log("error", e);
          message.error("An error occurred during OTP verification.");
        });
  };

  const onChange = (text) => {
    console.log("onChange:", text);
  };

  const sharedProps = {
    onChange,
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="verify-otp">
      <Row gutter={[16, 16]} className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={SignUpImg} alt="login" className="loginImg" />
            </div>
          </Col>
        )}

        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" className="logo" />
            <h2 className="heading">Verify OTP</h2>
            <div className="form-container">
              <div className="form">
                <Form
                  form={form}
                  name="verify_otp"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item label="Email" name="email" initialValue={email}>
                    <Input
                      className="input_custom"
                      prefix={<MdOutlineEmail />}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label="OTP"
                    name="otp"
                    rules={[
                      { required: true, message: "Please input your OTP!" },
                    ]}
                  >
                    <Input
                      maxLength={6}
                      {...sharedProps}
                      className="input_custom"
                      placeholder="Enter OTP"
                    />
                  </Form.Item>
                  {/* <Button
                    className="submitBtn"
                    type="primary"
                    htmlType="submit"
                    block
                  >
                    Verify OTP
                  </Button> */}
                  <CustomButton
                    htmlType="submit"
                    text=" Verify OTP"
                    bgColor="#3D3C6E"
                    width="100%"
                  />
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NewVerifyOtp;
