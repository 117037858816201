import React, { useEffect, useState } from "react";
import { Col, Row, Form, Input, Divider, message } from "antd";
import { useNavigate } from "react-router";
import loginImage from "../../assets/LoginImg.png";
import Logo from "../../assets/Logo.png";
import { MdOutlineEmail } from "react-icons/md";
import { SlLockOpen } from "react-icons/sl";
import googleImg from "../../assets/google.png";
import { LoginAPI } from "../../Constants/Api/Api";
import CustomButton from "../../Components/CustomButton/CustomButton";
import "./Login.scss";

const Login = () => {
  const [isMobile, setIsMobile] = useState(false);
  const roleID = "8";
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = (values) => {
    const headers = {
      timezone: "Asia/Kolkata",
      role_id: roleID,
    };
    const formData = {
      email: values.email,
      password: values.password,
    };

    LoginAPI(formData, headers)
      .then((res) => {
    
        if (res.data.success) {
          message.success(res.data.message);
          localStorage.removeItem("role_id");

          localStorage.setItem("role_id", res?.data?.data?.role_id);
          let Token = res?.data?.data?.tokens?.access?.token;
          if (Token) {
            localStorage.removeItem("corporateToken");
            localStorage.setItem("corporateToken", Token);
            navigate("/dashboard");
          }
        } else {
          message.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log("error", e);
        message.error(e.response.data.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login">
      <Row gutter={[16, 16]} className="row">
        {!isMobile && (
          <Col span={12}>
            <div className="leftSide">
              <img src={loginImage} alt="login" className="loginImg" />
              <div className="imageOverlay"></div>
            </div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={Logo} width={200} alt="logo" className="logo" />
            <h1 className="heading">Login</h1>
            <p className="para">Login with your email address & password</p>
            <div className="form">
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input
                    className="login_input"
                    placeholder="Email"
                    prefix={<MdOutlineEmail />}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    className="login_input"
                    placeholder="Password"
                    prefix={<SlLockOpen />}
                  />
                </Form.Item>
                <p
                  className="forgot_pass"
                  onClick={() => navigate("/forgotpassword")}
                >
                  Forgot Password?
                </p>
                <CustomButton
                  htmlType="submit"
                  text="Sign in"
                  bgColor="#3D3C6E"
                  width="100%"
                />
              </Form>
              {/* <Divider>OR</Divider>
              <button className="googleBtn" type="button">
                <img src={googleImg} alt="Google" /> Login with Google
              </button> */}
            </div>
          </div>
          <div className="bottom_text">
            Don’t have an account?{" "}
            <span
              onClick={() => navigate("/newsignup")}
              style={{ cursor: "pointer", color: "#3D3C6E" }}
            >
              Sign Up
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
