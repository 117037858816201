import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/dashboard";
import VerifyOTP from "./VerifyOTP/VerifyOTP";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
import Forgot from "./Pages/Forgot/Forgot";
import Reset from "./Pages/reset/reset";
import Profile from "./Pages/Profile/Profile";
import Login from "./Pages/Login/Login";
import NewSignUp from "./Pages/NewSignUp/NewSignUp";
import NewVerifyOtp from "./Pages/NewVerifyOtp/NewVerifyOtp";
import EmployeeList from "./Pages/EmployeeList/EmployeeList";
import AddEmployee from "./Components/AddEmployee/AddEmployee";
import ViewEmployee from "./Pages/ViewEmployee/ViewEmployee";
import ShareData from "./Pages/ShareData/ShareData";
import CoursesList from "./Components/CoursesList/CourseList";
import AssignCourseToEmployee from "./Components/AssignCourseToEmployee/AssignCourseToEmployee";
import PurchaseHistory from "./Pages/PurchaseHistory/PurchaseHistory";

const isAuthenticated = () => {

  return !!localStorage.getItem("corporateToken");
};

const PrivateRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/login" />;
};

const WebRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
    
        <Route
          exact
          path="/"
          element={isAuthenticated() ? <Navigate to="/dashboard" /> : <Login />}
        />

        <Route exact path="/newsignup" element={<NewSignUp />} />
        <Route exact path="/newverifyotp" element={<NewVerifyOtp />} />
        <Route exact path="/login" element={<Login />} />

        <Route exact path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        <Route exact path="/profile" element={<PrivateRoute element={<Profile />} />} />
        <Route exact path="/employeelist" element={<PrivateRoute element={<EmployeeList />} />} />
        <Route exact path="/sharedata" element={<PrivateRoute element={<ShareData />} />} />
        <Route exact path="/courseslist" element={<PrivateRoute element={<CoursesList />} />} />
        <Route
          exact
          path="/assigncoursetoemployee/:id"
          element={<PrivateRoute element={<AssignCourseToEmployee />} />}
        />
        <Route exact path="/addemployee" element={<PrivateRoute element={<AddEmployee />} />} />
        <Route exact path="/viewemployee/:id" element={<PrivateRoute element={<ViewEmployee />} />} />
        <Route exact path="/purchasehistory" element={<PrivateRoute element={<PurchaseHistory />} />} />

        <Route exact path="/verifyotp" element={<VerifyOTP />} />
        <Route exact path="/verifyemail" element={<VerifyEmail />} />
        <Route exact path="/forgotpassword" element={<Forgot />} />
        <Route exact path="/reset" element={<Reset />} />
      </Routes>
    </BrowserRouter>
  );
};

export default WebRoutes;
