import {
  HomeOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  AuditOutlined,
  FundViewOutlined,
} from "@ant-design/icons";
import { LiaHistorySolid } from "react-icons/lia";
import { FaRegShareSquare } from "react-icons/fa";
import { Dropdown } from "antd";
import { Button, Layout, Menu, theme } from "antd";
import { useState } from "react";
// import { getJWT } from "../../helpers/axios/api.service";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.png";
import { IoIosLogOut } from "react-icons/io";
import "./auth.scss";

const { Header, Sider, Content } = Layout;

const AuthLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  const currentRoute = location.pathname?.split("/")?.[1];

  const handleMenuClick = (e) => {
    if (e?.key === "/logout") {
      localStorage.clear();
      navigate("/");
    } else {
      navigate(e?.key);
    }
  };

  const items = [
    {
      label: "Profile",
      key: "/profile",
      icon: <UserOutlined size={22} />,
    },
    {
      label: "Logout",
      key: "/logout",
      icon: <IoIosLogOut size={18} />,
    },
    {
      label: "Reset Password",
      key: "/reset",
      icon: <FundViewOutlined size={22} />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed} width={230}>
        <div className="logo">
          <div className="py-2 mb-0">
            <img src={logo} className="logo-image" alt="logo" />
          </div>
        </div>
        <Menu
          onClick={handleMenuClick}
          theme="dark"
          mode="inline"
          selectedKeys={[`/${currentRoute}`]} 
          style={{ fontSize: "16px", fontWeight: 500, marginTop: "10px" }}
          items={[
            {
              key: "/dashboard",
              icon: <HomeOutlined style={{ fontSize: "18px", fontWeight: 500 }} />,
              label: "Dashboard",
            },
            {
              key: "/employeelist",
              icon: <UserOutlined style={{ fontSize: "18px", fontWeight: 500 }} />,
              label: "Employee",
            },
            {
              key: "/sharedata",
              icon: <FaRegShareSquare style={{ fontSize: "18px", fontWeight: 500 }} />,
              label: "Share Data",
            },
            {
              key: "/courseslist",
              icon: <AuditOutlined style={{ fontSize: "18px", fontWeight: 500 }} />,
              label: "Assign Course",
            },
            {
              key: "/purchasehistory",
              icon: <LiaHistorySolid style={{ fontSize: "20px", fontWeight: 500 }} />,
              label: "Purchase History",
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            type="text"
            icon={<MenuUnfoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <Dropdown.Button
            menu={menuProps}
            style={{ justifyContent: "flex-end", marginRight: 20 }}
            placement="bottomLeft"
            icon={<UserOutlined />}
          >
            User
          </Dropdown.Button>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 600,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default AuthLayout;
