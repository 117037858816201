import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { resetPassword } from "../../Constants/Api/Api";
import "./reset.scss";

const Reset = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const roleId = localStorage.getItem("role_id")
  const token = localStorage.getItem("corporateToken");
const headers = {
    "x-access-token":token,
    role_id:roleId
}
  const onFinish = (values) => {
 

    
    setLoading(true);

    // Create a FormData object
    const formData = new FormData();
    formData.append("old_password", values.oldPassword);
    formData.append("new_password", values.newPassword);
    formData.append("confirm_password", values.confirmPassword);

    // Pass the formData to the API
    resetPassword(formData, headers)
      .then((res) => {

        if (res.data.success) {
          navigate("/login");
          message.success("Password reset successfully");
          form.resetFields();
        }
      })
      .catch((error) => {
        message.error("Failed to reset password. Please try again.");
        console.error("Reset password error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="resetForm">
      <div className="reset-form-container">
        <h3>Reset Password</h3>
        <Form
          form={form}
          name="resetPasswordForm"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Please enter your old password" },
            ]}
          >
            <Input.Password placeholder="Old Password" size="large" />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please enter your new password" },
              { min: 8, message: "Password must be at least 8 characters" },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="New Password" size="large" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" size="large" />
          </Form.Item>

          <Form.Item>
            <Button
              icon="pi pi-check"
              label="Reset Password"
              severity="#3d3c6e"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Reset;
