import React, { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Select, message, Typography, Row, Col, Card } from "antd";
import {
  AssignCourse,
  GetTotalPrice,
  ListofEmployees,
  RemoveCartItem,
} from "../../Constants/Api/Api";
import AuthLayout from "../../Pages/layout/auth";
import CustomButton from "../CustomButton/CustomButton";
import SquarePaymentForm from "../SquarePaymentForm/SquarePaymentForm";
import { CloseOutlined } from "@ant-design/icons";
import "./AssignCourseToEmployee.scss";

const { Title } = Typography;

const AssignCourseToEmployee = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [finishStatus, setFinishStatus] = useState(false);
  const [isCheckout, setIsCheckout] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("corporateToken");
  const headers = useMemo(
    () => ({
      "x-access-token": token,
      role_id: 8,
    }),
    [token]
  );

  const reciver = useLocation();


  const [cartItems, setCartItems] = useState([]);
  const [isPageReloading, setIsPageReloading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ListofEmployees(id, headers)
        .then((res) => {
          const formattedData = res.data.data.map((employee) => ({
            label: employee.user_profile.name,
            value: employee.id,
            disabled: employee.is_course_assigned,
          }));
          setEmployees(formattedData);
        })
        .catch((error) => {
          console.error("Error fetching employees:", error);
          message.error("Error fetching employees");
        })
        .finally(() => setLoading(false));
    }
  }, [id, headers]);

  // Fetch cart items and total price
  const fetchTotalPrice = () => {
    GetTotalPrice(headers)
      .then((res) => {
        const data = res.data.data;
        setTotalPrice(data.totalPrice);
        setItemCount(data.cartItems.length);
        setCartItems(data.cartItems);
      })
      .catch((error) => {
        console.error("Error fetching total price:", error);
      });
  };

  useEffect(() => {
    fetchTotalPrice();
  }, [headers]);

  // Detect if the page is being reloaded and set a flag in localStorage
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Set a flag in localStorage indicating a page reload
      localStorage.setItem("isPageReloading", true);
      setIsPageReloading(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Check the page reload status on component mount and call RemoveCartItem if necessary
  useEffect(() => {
    const isPageReloading = localStorage.getItem("isPageReloading");

    if (isPageReloading) {
      // Remove the cart items if the page was reloaded
      RemoveCartItem(headers)
        .then((response) => {
          if (response.data.status === 200) {
            message.success("Cart items removed successfully");
          }
        })
        .catch((error) => {
          console.error("Error removing cart items:", error);
          message.error("Failed to remove cart items");
        })
        .finally(() => {
          // Clear the flag from localStorage
          localStorage.removeItem("isPageReloading");
        });
    }
  }, [headers]);

  // Handle back button navigation and cart removal
  const onBackButtonEvent = async (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm(
          "Do you want to go back? Your cart items will be removed."
        )
      ) {
        setFinishStatus(true);
        try {
          const response = await RemoveCartItem(headers);
       
          if (response.data.status === 200) {
            setTimeout(() => {
              message.success("Item removed successfully");
              navigate("/courseslist");
            }, 2000);
          }
        } catch (error) {
          console.error("Error removing cart items:", error);
          message.error("Failed to remove cart items");
          setFinishStatus(false);
        }
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setFinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [finishStatus, headers]);

  const handleAssign = async () => {
    try {
      setLoading(true);
      const res = await AssignCourse(selectedEmployees, id, headers);
      if (res.data.code === 200) {
        message.success("Course assigned successfully");
        fetchTotalPrice();
        setShowSummary(true);
      } else {
        message.error("Failed to assign course: " + res.data.message);
      }
    } catch (error) {
      console.error("Error assigning course:", error);
      message.error("Failed to assign course");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    message.info("Operation cancelled");
    setSelectedEmployees([]);
  };

  const handleCheckout = () => {
    setIsCheckout(true);
  };

  const handlePaymentCompleted = (data) => {
    message.success("Payment successful!");
    setIsCheckout(false);
    setTimeout(() => {
      navigate("/dashboard");
    }, 2000);
  };

  const handleCancelPayment = () => {
    setIsCheckout(false);
  };

  return (
    <AuthLayout>
      <div className="assign-course-to-employee">
        <Title level={3}>Assign Course to Employees</Title>
        <h5>{reciver.state}</h5>
        <Row gutter={16}>
          <Col span={showSummary ? 16 : 24}>
            <Card>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select employees..."
                options={employees}
                value={selectedEmployees}
                onChange={setSelectedEmployees}
                maxTagCount="responsive"
                loading={loading}
                size="large"
              />
              <div className="button-group">
                <CustomButton
                  text="Cancel"
                  bgColor="#d9d9d9"
                  color="#595959"
                  onClick={handleCancel}
                  margin="0 10px 0 0"
                />
                <CustomButton
                  text="Assign"
                  bgColor="#3d3c6e"
                  color="#fff"
                  onClick={handleAssign}
                />
              </div>
            </Card>
          </Col>
          {showSummary && (
            <Col span={8}>
              <Card className="summary-card">
                <h2>Summary</h2>
                <div className="summary-details">
                  <h6 className="summary-text">Number of Items:</h6>
                  <h6 className="summary-value">{itemCount}</h6>
                </div>
                     {/* Display each item in the cart */}
                     {cartItems.map((item) => (
                  <div key={item.id} className="cart-item">
                    <h6>
                      {item.cart_course
                        ? "Item 2: " + item.cart_course.name
                        : "Item 1: " + item.cart_test.name}
                    </h6>
               
                  </div>
                ))}
                <div className="summary-details">
                  <h6 className="summary-text">Total Amount:</h6>
                  <h6 className="summary-value">${totalPrice}</h6>
                </div>

           

                <CustomButton
                  text="Buy"
                  bgColor="#3d3c6e"
                  color="#fff"
                  width="100%"
                  onClick={handleCheckout}
                  className="buy-button"
                />
              </Card>
            </Col>
          )}
        </Row>
        {isCheckout && (
          <div className="payment-form-container">
            <button className="cancel-button" onClick={handleCancelPayment}>
              <CloseOutlined />
            </button>
            <SquarePaymentForm onPaymentCompleted={handlePaymentCompleted} />
          </div>
        )}
      </div>
    </AuthLayout>
  );
};

export default AssignCourseToEmployee;
