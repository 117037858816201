import axios from "axios";

// implementation with new Design

const Base_URL = "https://node.nucleargauges.com:5000/api/v1";

// SignUp Api
export const SignUpAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}/corporate/register`, formData, {
    headers,
  });
};

//Verify OTP API

export const VerifyOTPAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}/corporate/verify-otp`, formData, {
    headers,
  });
};

//Login API

export const LoginAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}/corporate/login`, formData, {
    headers,
  });
};
export const resetPassword = async(formData,headers)=>{
  return await axios.post(`${Base_URL}/corporate/reset-password`, formData, {
    headers,
  });
}

//Get All Employee Api
export const GetAllEmployeeAPI = async (headers) => {
  return await axios.get(`${Base_URL}/corporate/getAllEmployee`, {
    headers,
  });
};

//Delete Employee Api
export const DeleteEmployeeAPI = async (user_id, headers) => {
  return await axios.post(
    `${Base_URL}/corporate/deleteEmployee`,
    { user_id: user_id },
    { headers }
  );
};

//Add Employee
export const CreateEmployeeAPI = async (formData, headers) => {
  return await axios.post(`${Base_URL}/corporate/addEmployee`, formData, {
    headers,
  });
};

// Get Profile
export const GetProfileAPI = async (headers) => {
  return await axios.get(`${Base_URL}/corporate/profile`, { headers });
};

// Update Profile
export const UpdateProfileAPI = async (formData, headers) => {
  return await axios.put(
    `${Base_URL}/corporate/updateManagerProfile`,
    formData,
    { headers }
  );
};

// Forgot Password apis
export const sendOtpAPi = async (formData, headers) => {
  return await axios.post(`${Base_URL}/corporate/otp`, formData, {
    headers,
  });
};

export const VerifyAPi = async (formData, headers) => {
  return await axios.post(`${Base_URL}/corporate/verify-otp`, formData, {
    headers,
  });
};
export const ForgotPasswordApi = async (formData, headers) => {
  return await axios.post(
    `${Base_URL}/corporate/forgot-password`,
    formData,
    { headers }
  );
};

//Get All Share Data
export const GetAllShareData = async (headers) => {
  return await axios.get(
    `${Base_URL}/corporate/getListOfEmloyeesSharedData`,
    { headers }
  );
};
//Get All Courses
export const GetAllCourses = async () => {
  return await axios.get(`${Base_URL}/course/getAllCourse`);
};
//List of Employees
export const ListofEmployees = async (course_id, headers) => {
  return await axios.post(
    `${Base_URL}/corporate/assignCourse/listOfEmployees`,
    { course_id: course_id },
    { headers }
  );
};
//Assign Course To Employee
export const AssignCourse = async (user_id, course_id, headers) => {
  const config = {
    user_id: user_id,
    course_id: course_id,
  };
  return await axios.post(
    `${Base_URL}/corporate/assignCourse/assignCourseToEmployee`,
    config,
    { headers }
  );
};

//Get Total Price
export const GetTotalPrice = async (headers) => {
  return await axios.get(
    `${Base_URL}/corporate/assignCourse/getTotalPrice`,
    { headers }
  );
};

export const BuyItemsInCart = async (headers) => {
  return await axios.post(
    `${Base_URL}/corporate/assignCourse/buyItemsInCartForEmployee`,
    null,
    { headers }
  );
};

export const RemoveCartItem = async (headers) => {
  return await axios.post(
    `${Base_URL}/corporate/assignCourse/deleteAllItemsFromCart`,
    null,
    { headers }
  );
};

//get userby id
export const GetUserById = async (id) => {
  return await axios.get(`${Base_URL}/corporate/getEmployeeById/${id}`);
};

export const GetCourseDataByUserId = async (formData, id) => {
  return await axios.post(
    Base_URL + `/admin/user/getAllItemsFromDashboardForEmployee/${id}`,
    formData
  );
};

export const GetPurchaseHistory = async (formData, headers) => {
  return await axios.post(
    `${Base_URL}/corporate/assignCourse/getAllPurchasedItemForEmployee`,
    formData,
    { headers }
  );
};


// Get Square Key For Payment Getway
export const SquareKey = async (headers) => {
  return await axios.get(
    `https://node.nucleargauges.com:5000/api/v1/payment/square/square-key`,
    {
      headers,
    }
  );
};
export const TotalEmployeeAdded = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/totalAmountSpent`,
    {
      headers,
    }
  );
};
export const TotalCoursePurchased = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/totalCoursePurchased`,
    {
      headers,
    }
  );
};
export const MostPurchasedCourse = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/mostPurchasedCourse`,
    {
      headers,
    }
  );
};

export const NumberOfSharedData = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/numberOfSharedData`,
    {
      headers,
    }
  );
};
export const TotalAmountSpent = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/totalAmountSpent`,
    {
      headers,
    }
  );
};
export const CourseCompletedEmployees = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/countCompletedEmployees`,
    {
      headers,
    }
  );
};
export const EmployeeEngaged =async (headers)=>{
  return await axios.get(`${Base_URL}/admin/dashboard/countEngagedEmployees`,{headers})
}
export const EmployeesWithCertificates = async (headers) => {
  return await axios.get(
    `${Base_URL}/admin/dashboard/countEmployeesWithCertificates`,
    {
      headers,
    }
  );
};

export const EmployeeStatistics = async (data,headers) => {
  const formData = {
    period:data
  }
  return await axios.post(
    `${Base_URL}/admin/dashboard/employeeStatistics`,formData,
    {
      headers,
    }
  );
};
export const CourseStatistics = async (data,headers) => {
  const formData = {
    period:data
  }
  return await axios.post(
    `${Base_URL}/admin/dashboard/courseStatistics`,formData,
    {
      headers,
    }
  );
};


