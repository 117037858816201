import React, { useEffect, useState } from "react";
import { Space, Table, message, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import AuthLayout from "../../Pages/layout/auth";
import { GetAllCourses } from "../../Constants/Api/Api";
import "./CoursesList.scss";
import { SearchOutlined } from "@ant-design/icons";

const CoursesList = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // For filtered results
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(""); // For search input
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sortField: null,
    sortOrder: null,
  });

  const navigate = useNavigate();

  const fetchCourses = async (params = {}) => {
    setLoading(true);
    try {
      const res = await GetAllCourses();
      const formattedData = res.data.data.map((course) => ({
        id: course.id,
        course_name: course.name,
        course_duration: `${course.lecture_count} lectures`,
        course_price: course.price,
      }));
      setData(formattedData);
      setFilteredData(formattedData); // Initialize filteredData with all courses
    } catch (error) {
      console.error("Error fetching courses:", error);
      message.error("Error fetching courses");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses({
      page: tableParams.pagination.current,
      pageSize: tableParams.pagination.pageSize,
      sortField: tableParams.sortField,
      sortOrder: tableParams.sortOrder,
    });
  }, [tableParams]);

  const handleAssign = (courseId, course_name) => {
    navigate(`/assigncoursetoemployee/${courseId}`, { state: course_name });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };


  const onSearch = (value) => {
    const filtered = data.filter((item) =>
      item.course_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    onSearch(value);
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "course_name",
      key: "course_name",
      sorter: (a, b) => a.course_name.localeCompare(b.course_name),
    },
    {
      title: "Duration",
      dataIndex: "course_duration",
      key: "course_duration",
    },
    {
      title: "Price",
      dataIndex: "course_price",
      key: "course_price",
      render: (price) => `$${price}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon="pi pi-plus"
            rounded
            outlined
            style={{
              borderRadius: "25px",
              color: "#28a745",
              borderColor: "#28a745",
            }}
            onClick={() => handleAssign(record.id, record.course_name)}
          />
        </Space>
      ),
    },
  ];

  return (
    <AuthLayout>
      <div>
        <h3 className="page-title">COURSE MANAGEMENT</h3>
        <p className="page-sub-title">Course List View and Assign</p>
      </div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="header">
          <Input
            size="large"
            placeholder="Search..."
            prefix={<SearchOutlined />}
            className="searchInput"
            value={searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={filteredData} 
        rowKey={(record) => record.id}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </AuthLayout>
  );
};

export default CoursesList;
