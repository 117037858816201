import React from "react";
import "./CustomButton.scss";
const CustomButton = ({ text, onClick, bgColor, width,padding,margin,color,border,htmltype }) => {
  return (
    <button
      className="customBtn"
      htmltype={htmltype}
      onClick={onClick}
      style={{
        backgroundColor: bgColor,
        width:width,
        padding,padding,
        margin:margin,
        color:color,
        border:border
      }}
    >
      {text}
    </button>
  );
};

export default CustomButton;
