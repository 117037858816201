import React, { useEffect, useState } from "react";
import logoImg from "../../assets/Logo.png";
import { Col, Form, Input, message, Row } from "antd";
import loginImage from "../../assets/LoginImg.png";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordApi, sendOtpAPi, VerifyAPi } from "../../Constants/Api/Api";
import CustomButton from "../../Components/CustomButton/CustomButton";
import "./Forgot.scss";

const Forgot = () => {
  const navigate = useNavigate();

  const headers = {
    role_id: "8",
  };
  const [isMobile, setIsMobile] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState(""); 
  const [step, setStep] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSendOtp = async (values) => {
    const formData = {
      email: values?.email,
      type: "forgot_password",
    };
    sendOtpAPi(formData, headers)
      .then((res) => {
        // console.log("res", res);
        message.success("OTP sent to your email!");
        setEmail(values.email);
        setStep(2);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const handleVerifyOtp = async () => {
    const formData = {
      email: email,
      otp: otp, // Using regular input for OTP
      type: "forgot_password",
    };

    VerifyAPi(formData, headers)
      .then((res) => {

        message.success(res?.data?.message);
        // navigate("/login")
        setToken(res?.data?.data?.token);
        setStep(3);
      })
      .catch((e) => {
        console.log("verify OTP",e);
      });
  };

  const handlePasswordChange = async (values) => {
    const formData = {
      email: email,
      password: values.password,
      confirm_password: values?.confirmPassword,
      token: token,
    };
  
    ForgotPasswordApi(formData, headers)
      .then((res) => {
  
        message.success(res?.data?.message);
        navigate("/");
      })
      .catch((e) => {
        console.log("forgot pass : error",e);
      });
  };

  return (
    <div className="ForgotPassword">
      <Row className="row">
        {!isMobile && (
          <Col span={12} className="leftSide">
            <img src={loginImage} alt="login" className="loginImg" />
            <div className="imageOverlay"></div>
          </Col>
        )}
        <Col span={isMobile ? 24 : 12} className="column2">
          <div className="rightSide">
            <img src={logoImg} width={200} alt="logo" />
            <h3>Forgot Password!</h3>
            {step === 1 && (
              <div className="SendEmail">
                <div className="form">
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={handleSendOtp}
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                      ]}
                    >
                      <Input
                        className="input_custom"
                        placeholder="example123@gmail.com"
                      />
                    </Form.Item>
                    <Form.Item>
                      <CustomButton
                        htmlType="submit"
                        text="Send OTP"
                        bgColor="#3D3C6E"
                        width="100%"
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="VerifyOtp">
                <div className="form">
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={handleVerifyOtp}
                  >
                    <Form.Item
                      label="OTP"
                      name="otp"
                      rules={[
                        { required: true, message: "Please input your OTP!" },
                      ]}
                    >
                      <Input
                        className="input_custom"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item>
                      {/* <Button className="btn_theme" htmlType="submit" block>
                        Verify OTP
                      </Button> */}
                      <CustomButton
                        htmlType="submit"
                        text="Verify OTP"
                        bgColor="#3D3C6E"
                        width="100%"
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="forgot-password">
                <div className="form">
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    layout="vertical"
                    onFinish={handlePasswordChange}
                  >
                    <Form.Item
                      label="Create Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          min: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                        {
                          pattern:
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                          message:
                            "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
                        },
                      ]}
                    >
                      <Input.Password  className="input_custom" />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmPassword"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password  className="input_custom" />
                    </Form.Item>
                    <Form.Item>
                      {/* <Button className="btn_theme" htmlType="submit" block>
                        Change Password
                      </Button> */}
                      <CustomButton
                        htmlType="submit"
                        text=" Change Password"
                        bgColor="#3D3C6E"
                        width="100%"
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Forgot;
